import axios from 'axios'
import { Loading, Message} from 'element-ui';

let loading;


// 创建axios 实例
// const service = axios.create({
//     baseURL: config.baseURL, // api的base_url
//     timeout: 10000 // 请求超时时间
// })
// axios.defaults.baseURL = '/';

axios.defaults.timeout = 3000000;
//post请求头设置
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// request 拦截器
axios.interceptors.request.use(
    config => {
        // 这里可以自定义一些config 配置

        return config
    },
    error => {
        //  这里处理一些请求出错的情况

        return Promise.error(error); 
    }
)

// response 拦截器
axios.interceptors.response.use(
    response => {
        if (response.status === 200) {   
            if(response.data.respCode == "0000" || response.data.code == "0") {
               return Promise.resolve(response);
            }  
            // else if(response.data.respCode == "400"){
            //     window.location.href = "/"
            // }  
            else {
                Message.error(response.data.msg);
            }    
        } else {            
            return Promise.reject(response);        
        }    
    },
    error => {
        // 这里处理一些response 出错时的逻辑

        return Promise.reject(error)
    }
)

/** 
 * get方法，对应get请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
 export function get(url, params){    
    return new Promise((resolve, reject) =>{     
        let config = {

            headers: {
                'Authorization': "Bearer " + sessionStorage.getItem("Authorization")
            }
        }
        axios.get(url, {        
            params: params        
        },config)        
        .then(res => {            
            resolve(res.data);        
        })        
        .catch(err => {            
            reject(err.data)        
        })    
    });
}

/**  
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */


export function post(url, params, isLoading) { 
    if(!isLoading) {
        if(isLoading != false){
            isLoading = true;
        }
    }
    return new Promise((resolve, reject) => {        
        
        if(isLoading){
            loading = Loading.service({
                lock: true,
                text: '努力加载中...',//可以自定义文字
                spinner:'el-icon-loading',//自定义加载图标类名
                background: 'rgba(0, 0, 0, 0.7)'//遮罩层背景色
            });   
        }
        let config = {

            headers: {
                'Authorization': "Bearer " + sessionStorage.getItem("Authorization")
            }
        }
        axios.post(url, params, config)        
        .then(res => {
            if(isLoading){
                loading.close(); 
            }
               
            resolve(res.data);        
        })        
        .catch(err => {
            loading.close();      
            reject(err.data)        
        })    
    });
}

/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function formDataPost(url, params, isLoading) { 
    if(!isLoading) {
        if(isLoading != false){
            isLoading = true;
        }
    }
    return new Promise((resolve, reject) => {        
        
        if(isLoading){
            loading = Loading.service({
                lock: true,
                text: '努力加载中...',//可以自定义文字
                spinner:'el-icon-loading',//自定义加载图标类名
                background: 'rgba(0, 0, 0, 0.7)'//遮罩层背景色
              });   
        }
        // let token = sessionStorage.getItem("Authorization");
        // if(token) {
        //     params.Authorization = "Bearer "+token;
        // }
        let config = {

            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': "Bearer " + sessionStorage.getItem("Authorization")
            }
        }
        const formData = new FormData();
            Object.keys(params).forEach((key) => {
            formData.append(key, params[key]);
        });
        axios.post(url,formData,config)        
        .then(res => {
            if(isLoading){
                loading.close(); 
            }
               
            resolve(res.data);        
        })        
        .catch(err => {
            loading.close();      
            reject(err.data)        
        })    
    });
}

export function filePost(url, params, config) {
    // let userAuth = JSON.parse(sessionStorage.getItem("userAuth"));
    return new Promise((resolve, reject) => {
        axios.create({
            timeout: 15000,
            headers: {
                "Content-Type": "application/json-patch+json",
            },
            responseType: "arraybuffer" //必须设置，不然导出的文件无法打开
        })
            .post(url, params, config)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    });
}

/** 
 * getUrlParam方法，获取url的请求参数
 * @param {String} key 请求参数的key
 */
export function getUrlParam(key){
    var str = window.location.href.split("?");
   

    if(str.length >1 ){
        str = str[1];
    }else{
        return null;
    }
    //str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
    // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
    var arr = str.split("&");
    var obj = new Object();
    // 将每一个数组元素以=分隔并赋给obj对象
    for (var i = 0; i < arr.length; i++) {
        var tmp_arr = arr[i].split("=");
        obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
    }
    return obj[key];
}

// export default service


