import {post ,get, formDataPost} from '../utils/request'

//鸡大屏数据查询
const queryDatascreen9ById = p => post('/datascreen-web/datascreen/authout/queryDatascreen9ById?id=1', p);

//鸡大屏iot
const queryRedisIotDataByLandIds = p => post('/landWithOut/queryRedisIotDataByLandIds', p);

//羊大屏
const queryDatascreen1ById = p => post('/datascreen-web/datascreen/authout/queryDatascreen1ById?id=1', p);

//羊小屏
const queryDatascreen2ById = p => post('/datascreen-web/datascreen/authout/queryDatascreen2ById?id=1', p);

//羊小屏iot
const withOutQueryRedisByIotEmData = p => post('/shangheWithOut/withOutQueryRedisByIotEmData', p, false);


const withOutQueryRedisByIotEmData2 = p => post('/shangheWithOut/withOutQueryRedisByIotEmData2', p, false);
//羊小屏水塔
const withOutqueryRedisByWaterDeep = p => post('/shangheWithOut/withOutqueryRedisByWaterDeep', p);

//加工大屏
const queryDatascreen15ById = p => post('/datascreen-web/datascreen/authout/queryDatascreen15ById?id=1', p);

//马铃薯大屏
const queryDatascreen3ById = p => post('/datascreen-web/datascreen/authout/queryDatascreen3ById?id=1', p);

//大地种业马铃薯小屏
const queryDatascreen5ById = p => get('/datascreen-web/datascreen/authout/queryDatascreen5ById', p);

//明杰种业马铃薯小屏
const queryDatascreen6ById = p => post('/datascreen-web/datascreen/authout/queryDatascreen6ById?id=1', p);

//榆河种业马铃薯小屏
const queryDatascreen4ById = p => post('/datascreen-web/datascreen/authout/queryDatascreen4ById?id=1', p);

//谷物杂粮大屏：
const queryDatascreen11ById = p => post('/datascreen-web/datascreen/authout/queryDatascreen11ById?id=1', p);

//共享农机大屏：
const queryDatascreen12ById = p => post('/datascreen-web/datascreen/authout/queryDatascreen12ById?id=1', p);

//仓储冷链大屏：
const queryDatascreen10ById = p => post('/datascreen-web/datascreen/authout/queryDatascreen10ById?id=1', p);

//地块信息
const queryPlantLandListForPdp = p => post('/datascreen-web/datascreen/authout/queryPlantLandListForPdp', p);

//肉牛大屏详情
const queryDatascreen16Info = p => post('/datascreen-web/datascreen/authout/queryDatascreen16Info', p);

//大漠蔬菜详情
const queryDatascreen17Info = p => post('/datascreen-web/datascreen/authout/queryDatascreen17Info', p);

//企业信息
const querydatascreen3EnterpriseMapInfo = p => post('/datascreen-web/datascreen/authout/queryDatascreen3EnterpriseMapInfo', p);

//iot设备信息
const queryIotDataByDate = p => post('/datascreen-web/datascreen/authout/queryIotDataByDate', p, false);

// //预警
const datascreen5WarnQueryForPageLists = p => post('/datascreen-web/datascreen/authout/datascreen5Warn/queryForPageList', p, false);

//大漠蔬菜全区设施农业主要播种品种面积与产量统计分析
const datascreen17AllAreaSowingStatisticsqueryForList = p => post('/datascreen-web/datascreen/authout/datascreen17AllAreaSowingStatistics/queryForList', p);

//大漠蔬菜各乡镇设施蔬菜建设面积统计
const datascreen17TownshipsVegetableAreaStatisticsqueryForList = p => post('/datascreen-web/datascreen/authout/datascreen17TownshipsVegetableAreaStatistics/queryForList', p);

//大漠蔬菜全区设施农业主要播种品种价格区间
const datascreen17AllAreaSowingPricequeryForList = p => post('/datascreen-web/datascreen/authout/datascreen17AllAreaSowingPrice/queryForList', p);

//谷物杂粮全区设施农业主要播种品种面积与产量统计分析
const datascreen18AllAreaSowingStatisticsqueryForList = p => post('/datascreen-web/datascreen/authout/datascreen18AllAreaSowingStatistics/queryForList', p);

//谷物杂粮各乡镇设施蔬菜建设面积统计
const datascreen18TownshipsSowingStatisticsqueryForList = p => post('/datascreen-web/datascreen/authout/datascreen18TownshipsSowingStatistics/queryForList', p);

//大漠蔬菜最高最低价
const datascreen18AllAreaSowingPricequeryForList = p => post('/datascreen-web/datascreen/authout/datascreen18AllAreaSowingPrice/queryForList', p);

//谷物杂粮
const datascreen18queryById = p => post('/datascreen-web/datascreen/authout/datascreen18/queryById', p, false);

//谷物杂粮标点
const datascreen18WorkMapqueryForList = p => post('/datascreen-web/datascreen/authout/datascreen18WorkMap/queryForList', p);

//共享农机
const iotDevGpsqueryForList = p => post('/agriculture-web/agriculture/authout/iotDevGps/queryForList', p, false);

//鸡脚环
const queryDatascreen9JbqHistory = p => post('/datascreen-web/datascreen/authout/queryDatascreen9JbqHistory', p);

//温度
const queryMaxAndMinTemperatureData = p => post('/datascreen-web/datascreen/authout/queryMaxAndMinTemperatureData', p);


//车辆总数
const queryCountByOnlineMachineryTotal = p => post('/agriculture-web/agriculture/authout/iotDevGps/queryCountByOnlineMachineryTotal', p, false);

//仓储冷链乡镇
const datascreen10TownshipMapConfqueryForList = p => post('/datascreen-web/datascreen/authout/datascreen10TownshipMapConf/queryForList', p);

//共享农机乡镇
const datascreen12TownshipMapConfqueryForList = p => post('/datascreen-web/datascreen/authout/datascreen12TownshipMapConf/queryForList', p);

//鸡大屏乡镇
const datascreen9TownshipMapConfqueryForList = p => post('/datascreen-web/datascreen/authout/datascreen9TownshipMapConf/queryForList', p);

//加工产业乡镇
const datascreen15TownshipMapConfqueryForList = p => post('/datascreen-web/datascreen/authout/datascreen15TownshipMapConf/queryForList', p);

//马铃薯大屏乡镇
const datascreen3TownshipMapConfqueryForList = p => post('/datascreen-web/datascreen/authout/datascreen3TownshipMapConf/queryForList', p);

//鸡产蛋
const eggsLookeggListLed = p => post('/yiKuanDanOut/eggsLookeggListLed', p);

//鸡步数
const vervelTjListLed = p => post('/yiKuanDanOut/vervelTjListLed', p);

//鸡粮食消耗
const eggsFeedListLed = p => formDataPost('/yiKuanDanOut/eggsFeedListLed', p);

//鸡口粮种类占比
const getEggsMix = p => post('/yiKuanDanOut/getEggsMix', p);

// 鸡汇总
const zwcrListLed = p => post('/yiKuanDanOut/zwcrListLed', p);

//疾病监测预警-防疫-已打-查询统计12个月疫苗已打数据列表
const eggsAntAndMonthListLed = p => post('/yiKuanDanOut/eggsAntAndMonthListLed', p);

//疾病监测预警-防疫-治疗-查询统计12个月疫苗已打数据列表
const eggsCureAndMonthListLed = p => post('/yiKuanDanOut/eggsCureAndMonthListLed', p);

//疾病监测预警-防疫-预警-查询统计12个月疫苗已打数据列表
const eggsWarnAndMonthListLed = p => post('/yiKuanDanOut/eggsWarnAndMonthListLed', p);

//鸡场外部iot
const queryEc = p => post('/datascreen-web/datascreen/authout/iotEnvironmentalControl/queryEc', p);

export default {
    queryDatascreen9ById,
    queryRedisIotDataByLandIds,
    queryDatascreen1ById,
    queryDatascreen2ById,
    queryDatascreen15ById,
    withOutQueryRedisByIotEmData,
    withOutqueryRedisByWaterDeep,
    queryDatascreen3ById,
    queryDatascreen5ById,
    queryDatascreen6ById,
    queryDatascreen4ById,
    queryDatascreen11ById,
    queryDatascreen12ById,
    queryDatascreen10ById,
    queryDatascreen16Info,
    queryPlantLandListForPdp,
    queryDatascreen17Info,
    querydatascreen3EnterpriseMapInfo,
    queryIotDataByDate,
    datascreen5WarnQueryForPageLists,
    datascreen17AllAreaSowingStatisticsqueryForList,
    datascreen17TownshipsVegetableAreaStatisticsqueryForList,
    datascreen17AllAreaSowingPricequeryForList,
    datascreen18AllAreaSowingStatisticsqueryForList,
    datascreen18TownshipsSowingStatisticsqueryForList,
    datascreen18AllAreaSowingPricequeryForList,
    datascreen18queryById,
    datascreen18WorkMapqueryForList,
    iotDevGpsqueryForList,
    queryDatascreen9JbqHistory,
    queryMaxAndMinTemperatureData,
    queryCountByOnlineMachineryTotal,
    datascreen10TownshipMapConfqueryForList,
    datascreen12TownshipMapConfqueryForList,
    datascreen9TownshipMapConfqueryForList,
    datascreen15TownshipMapConfqueryForList,
    datascreen3TownshipMapConfqueryForList,
    eggsLookeggListLed,
    vervelTjListLed,
    eggsFeedListLed,
    getEggsMix,
    zwcrListLed,
    eggsAntAndMonthListLed,
    eggsCureAndMonthListLed,
    eggsWarnAndMonthListLed,
    queryEc,
    withOutQueryRedisByIotEmData2
}